import React, { useEffect, useState, lazy, Suspense } from "react";
import { motion, useSpring, useTransform, useScroll } from 'framer-motion'
import useTheme from "@mui/system/useTheme";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/system/Box";
import BowListLazy from "../../_shared/shaping/BowListLazy"
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport"
import { useBreakpoint } from "../../_shared/contextBreakpointMediaQ";
import SeparatorAbsSvg2 from "../../_shared/shaping/SeparatorAbsSvg2";
import { POLYGON_RECTANGLE_10_POINTS_LEFT, POLYGON_RECTANGLE_10_POINTS_RIGHT, POLYGON_STAR } from "../../../utils/clipPathsConstants";

const LazyTabsPraceExcel = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'TabsPraceExcelLazy' */ './TabsPraceExcel'));
const LazyTabsPriklady = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'TabsPrikladyLazy' */ './TabsPriklady'));



const bLOptionsDouble = {
  itemHeightPx: 120,
  primarySecondarySeparator: "|",
}

const bLOptionsDoubleMd = {
  itemHeightPx: 100,
  primarySecondarySeparator: "|",
}




const bowListL = {
  motionDirection: "left",
  bowDirection: "left",
  bowPosition: "left",
}

const bowListR = {
  motionDirection: "right",
  bowDirection: "right",
  bowPosition: "right",
}

const bowListL2 = {
  motionDirection: "left",
  bowPosition: "left",
  bowDirection: "right",
}

const bowListR2 = {
  motionDirection: "right",
  bowPosition: "left",
  bowDirection: "left",
}




const styleBLBordersBRTL50px = {
  borderBottomRightRadius: "50px",
  borderTopLeftRadius: "50px",
}
const styleBLBordersBLTR50px = {
  borderBottomLeftRadius: "50px",
  borderTopRightRadius: "50px",
}
const styleBLBordersTLBR20 = {
  borderTopLeftRadius: "20%",
  borderBottomRightRadius: "20%",
}
const styleBLBordersTRBL20 = {
  borderTopRightRadius: "20%",
  borderBottomLeftRadius: "20%",
}

const styleSxWrap = {

  display: 'block',
  "& .container": {
    my: 0,
    p: [ 0.5, 1, 2, 3 ],

    "&.contUp": {
      color: "primary.contrastText",
      background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
      pb: 0,
      py: 0,
    },
    "&.contFeat": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      my: 0,
      bgcolor: "primary2.main",
      color: "primary2.contrastText",
    },
    "&.contPr": {
      color: "primary.contrastText",
    },
    "&.contInsp": {
      color: "primary2.contrastText",
    },
    "& .wrapTitleBoost": {
      width: "100%",

    },
  },
  "& .gridItem": {
    display: "flex",
    justifyContent: "center"
  },
}






let fontSizeBL = "small"
const listPaddingTopPx = 20
const listPaddingBottomPx = 20
const listMarginTopPx = 0
const listMarginBottomPx = 0

function MainUp(props) {
  const { title } = props
  const breakpoint = useBreakpoint()

  const theme = useTheme()

  const [ isComplete, setIsComplete ] = useState(false);
  const { scrollYProgress } = useScroll();
  const yRange = useTransform(scrollYProgress, [ 0, 0.9 ], [ 0, 1 ]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  // useEffect(() => yRange.onChange((v) => setIsComplete(v >= 1)), [ yRange ]);

  useEffect(() => {
    const unsubscribe = yRange.on("change", (v) => setIsComplete(v >= 1));
    return () => unsubscribe();
  }, [ yRange ]);


  switch (breakpoint) {
    case "xs":
      bLOptionsDoubleMd.itemHeightPx = 40
      bLOptionsDoubleMd.itemsXDistancePx = 20
      bLOptionsDouble.itemHeightPx = 50
      bLOptionsDouble.itemsXDistancePx = 20

      fontSizeBL = "small" //"0.9rem" // "unset" // "1rem"
      break;
    case "sm":
      bLOptionsDoubleMd.itemHeightPx = 30
      bLOptionsDoubleMd.itemsXDistancePx = 20
      bLOptionsDouble.itemHeightPx = 30
      bLOptionsDouble.itemsXDistancePx = 20
      fontSizeBL = "small"

      break;
    case "md":
      bLOptionsDoubleMd.itemHeightPx = 50
      bLOptionsDoubleMd.itemsXDistancePx = 20
      bLOptionsDouble.itemHeightPx = 50
      bLOptionsDouble.itemsXDistancePx = 20
      fontSizeBL = "small"
      break;
    default:
      bLOptionsDoubleMd.itemHeightPx = 40
      bLOptionsDoubleMd.itemsXDistancePx = 20
      bLOptionsDouble.itemHeightPx = 40
      bLOptionsDouble.itemsXDistancePx = 20
      fontSizeBL = "small"
      break;
  }
  const bLOptionsDoubleMdH = { ...bLOptionsDoubleMd }

  const sepFillColor = theme.palette.primary2.dark
  const sepFillColorContrastText = theme.palette.primary2.contrastText

  return (
    <Box component="div"
      sx={styleSxWrap}
    >
      <svg
        className="progress-icon"
        viewBox="0 0 50 50"
        style={{ position: "fixed", top: 90, left: 50, width: 100, zIndex: 1 }}
      >
        <motion.path
          fill="none"
          strokeWidth="2"
          stroke="green"
          strokeDasharray="0 1"
          d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
          style={{
            pathLength,
            rotate: 90,
            translateX: 5,
            translateY: 5,
            scaleX: -1
          }}
          key="mainUp-prog-path1"

        />
        <motion.path
          fill="none"
          strokeWidth="2"
          stroke="green"
          d="M14,26 L 22,33 L 35,16"
          initial={false}
          strokeDasharray="0 1"
          animate={{ pathLength: isComplete ? 1 : 0 }}
          key="mainUp-prog-path2"
        />
      </svg>

      <AnimateScrollIntoViewport
        name={`SeparatorAbsSvg2-kompl1-${breakpoint}`}
        durationIn={0.5}
        durationOut={0.2}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
        exitFramer={{ clipPath: POLYGON_STAR, }}
        styleToMerge={{ clipPath: POLYGON_STAR, }}
        key={`SeparatorAbsSvg2-kompl1-${breakpoint}`}
      >
        <SeparatorAbsSvg2
          name="sepNormalRel2FullHorizontalHills"
          id="ProgramPreVase"
          key="ProgramPreVase"
          optionsSep={{
            styleWrapSep: {
              lineHeight: "inherit"
            },
            styleSvg: {
              height: "150px",
              transform: 'rotateY(180deg)'
            },
            svgYPadding: 0,
            isRelative: true,
          }} >
          <Container
            maxWidth={false}
            className="contFeat container"
            style={{
              position: "relative", marginTop: 0, marginBottom: 0, borderRadius: 'unset',
              backgroundColor: sepFillColor,
              color: sepFillColorContrastText
            }}
          >
            <Container>
              <Grid container spacing={2}
              >
                <Grid item xs={12} md={6}
                  className="gridItem"
                >
                  <AnimateScrollIntoViewport
                    name={`BL-mainUp-ProgramPreVase-${breakpoint}`}
                    initialInView={true}
                    animateFramer={{
                      x: 0,
                      y: 0,
                      opacity: 1
                    }}
                    exitFramer={{
                      x: -200,
                      y: -100,
                      opacity: 0
                    }}
                    styleToMerge={{ x: -200, y: -100, opacity: 0 }}
                    key={`BL-mainUp-ProgramPreVase-${breakpoint}`}
                  >

                    <BowListLazy
                      name="BL-mainUp-ProgramPreVase"
                      options={{
                        nameStyleList: "primary2",
                        nameStyleItem: "secondary",
                        ...bLOptionsDouble,
                        ...bowListL,
                        styleList: {
                          ...styleBLBordersBRTL50px,
                          fontSize: fontSizeBL,
                        },
                        listPaddingTopPx,
                        listPaddingBottomPx,
                        listMarginTopPx,
                        listMarginBottomPx,
                      }}
                      key="BL-mainUp-ProgramPreVase"
                    >
                      {[ "Program pre vaše dáta priamo v Excel súbore alebo ako plugin pre MS Office.",
                        "Tvorba softvérovej aplikácie podľa vášho zadania.",
                      ]}
                    </BowListLazy>
                  </AnimateScrollIntoViewport>
                </Grid>
                <Grid item xs={12} md={6}
                  className="gridItem"
                >
                  <AnimateScrollIntoViewport
                    // name="BL-mainUp-JednoducheUtility"
                    name={`BL-mainUp-JednoducheUtility-${breakpoint}`}
                    animateFramer={{
                      x: 0,
                      y: 0,
                      opacity: 1
                    }}
                    exitFramer={{
                      x: 200, y: -100,
                      opacity: 0
                    }}
                    styleToMerge={{ opacity: 0, x: 200, y: -100, }}
                    key={`BL-mainUp-JednoducheUtility-${breakpoint}`}
                  // key="BL-mainUp-JednoducheUtility"
                  >

                    <BowListLazy
                      name={`BL-mainUp-JednoducheUtility`}
                      options={{
                        ...bLOptionsDouble,
                        ...bowListR,
                        nameStyleList: "secondary",
                        nameStyleItem: "primary",
                        styleList: {
                          ...styleBLBordersBLTR50px,
                          fontSize: fontSizeBL,
                        },
                        listPaddingTopPx,
                        listPaddingBottomPx,
                        listMarginTopPx,
                        listMarginBottomPx,
                      }}
                      key={`BL-mainUp-JednoducheUtility`}
                    >
                      {[
                        "Jednoduché utility v MS Excel alebo komplexné VBA programy.",
                        "Pre opakujúce sa činnosti je utilita/program nevyhnutnosť.||často, tiež príp. menej často, ak ide zložitejšie úlohy alebo pre tie, ktoré vám jednoducho berú čas..."
                      ]}
                    </BowListLazy>
                  </AnimateScrollIntoViewport>

                </Grid>
              </Grid>
            </Container>
          </Container>
        </SeparatorAbsSvg2>
      </AnimateScrollIntoViewport>
      <AnimateScrollIntoViewport
        // animateFramer={{
        //   x: 0,
        //   opacity: 1
        // }}
        // exitFramer={{ x: -200, opacity: 0 }}
        // styleToMerge={{ x: -200, opacity: 0 }}
        name={`grid-ViacVykonanej-${breakpoint}`}
        key="grid-ViacVykonanej-${deviceSize}"
      >
        <SeparatorAbsSvg2
          name="sepNormalRel2FullHorizontalHills"
          id="Vyhody"
          key="Vyhody"
          optionsSep={{
            styleWrapSep: {
              lineHeight: "inherit"
            },
            styleSvg: {
              height: "100px",
            },
            svgYPadding: 0,
            isRelative: true,
          }} >


          <Container
            maxWidth={false}
            className="contFeat container"
            style={{
              position: "relative", marginTop: 0, marginBottom: 0, borderRadius: 'unset', backgroundColor: sepFillColor,
              color: sepFillColorContrastText
            }}
            key="contFeat">
            <Typography
              sx={{ fontSize: [ "1rem", "1.2rem", "1.4rem" ] }}
            >
              Výhody
            </Typography>
            <Grid container
              maxWidth="lg"
              spacing={1}>
              <Grid item xs={12} md={6}
                className="gridItem"
              >
                <AnimateScrollIntoViewport
                  name={`BL-mainUp-ViacVykonanej-${breakpoint}`}
                  animateFramer={{
                    x: 0,
                    opacity: 1
                  }}
                  exitFramer={{ x: -200, opacity: 0 }}
                  styleToMerge={{ x: -200, opacity: 0 }}
                  key={`BL-mainUp-ViacVykonanej-${breakpoint}`}
                >
                  <BowListLazy
                    name={`BL-mainUp-ViacVykonanej`}
                    options={{
                      nameStyleList: "secondary",
                      nameStyleItem: "primary",
                      tick: 2,
                      ...bLOptionsDoubleMd,
                      ...bowListL2,
                      styleList: {
                        ...styleBLBordersTLBR20,
                        fontSize: fontSizeBL,
                      },
                      listPaddingTopPx,
                      listPaddingBottomPx,
                      listMarginTopPx,
                      listMarginBottomPx,
                    }}
                    key={`BL-mainUp-ViacVykonanej`}
                  >
                    {[
                      "viac vykonanej práce za menej času",
                      "ovládať pripravenú aplikáciu vie aj úplný začiatočník v Exceli",
                      "ľahký prístup k dátam cez formuláre",
                      "ušetrenie možno až 90% vášho času stráveného s MS Office",
                      "konzistentné ukladanie dát",
                      "lepší dizajn dát",
                      "vylepšenia o rôzne súvislosti, možno lepšiu matematiku",
                      "spracovanie dáta jedným klikom"
                    ]}
                  </BowListLazy>
                </AnimateScrollIntoViewport>
              </Grid>
              <Grid item xs={12} md={6}
                className="gridItem"
              >
                <AnimateScrollIntoViewport
                  name={`BL-mainUp-DataBezChyb-${breakpoint}`}
                  initialInView={true}
                  animateFramer={{
                    x: 0,
                    opacity: 1
                  }}
                  exitFramer={{ x: 200, opacity: 0 }}
                  styleToMerge={{ x: 200, opacity: 0 }}
                  key={`BL-mainUp-DataBezChyb-${breakpoint}`}
                >
                  <BowListLazy
                    name={`BL-mainUp-DataBezChyb`}
                    options={{
                      nameStyleList: "primary2",
                      nameStyleItem: "secondary",
                      tick: 1,
                      ...bLOptionsDoubleMd,
                      ...bowListR2,
                      styleList: {
                        ...styleBLBordersTRBL20,
                        fontSize: fontSizeBL,
                      },
                      listPaddingTopPx,
                      listPaddingBottomPx,
                      listMarginTopPx,
                      listMarginBottomPx,
                    }}
                    key={`BL-mainUp-DataBezChyb`} >
                    {[
                      "dáta bez chýb",
                      "dáta prehľadné",
                      "dáta možno porovnávať",
                      "dáta možno filtrovať podľa rôznych kritérií",
                      "dáta prezentovať, reportovať (na papieri alebo v počítači)",
                      "dáta validovať, prepočítavať",
                      "dáta úplne pod vašou kontrolou"
                    ]}
                  </BowListLazy>
                </AnimateScrollIntoViewport>
              </Grid>
            </Grid>
          </Container>
        </SeparatorAbsSvg2>
      </AnimateScrollIntoViewport>
      <AnimateScrollIntoViewport
        durationIn={0.5}
        durationOut={0.2}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
        exitFramer={{ clipPath: POLYGON_STAR, }}
        styleToMerge={{ clipPath: POLYGON_STAR, }}
        name={`sepTabsPriklady-${breakpoint}`}
        key={`sepTabsPriklady-${breakpoint}`}
      >
        <SeparatorAbsSvg2
          name="sepNormalRel2FullHorizontalHills"
          id="TabsPriklady"
          key="TabsPriklady"
          optionsSep={{
            dPathOrderNotIn: [ 1 ],
            styleWrapSep: {
              lineHeight: "inherit"
            },
            styleSvg: {
              height: "150px",
            },
            svgYPadding: 0,
            isRelative: true,
          }} >
          <Container
            maxWidth={false}
            className="container contPr"
            style={{
              borderRadius: 'unset', backgroundColor: sepFillColor,
              color: sepFillColorContrastText
            }}
            key="contTabsPriklady"
          >
            <Container maxWidth="lg"          >
              <Suspense>
                <LazyTabsPriklady />
              </Suspense>
            </Container>
          </Container>
        </SeparatorAbsSvg2>
      </AnimateScrollIntoViewport>
      <SeparatorAbsSvg2
        name="sepNormalRel2FullHorizontalHills"
        id="TabsPraceExcel"
        passedControllingInViewport={false}
        key="TabsPraceExcel"
        optionsSep={{
          dPathOrderNotIn: [ 2 ],
          styleWrapSep: {
            lineHeight: "inherit"
          },
          styleSvg: {
            height: "150px",
            transform: 'rotateY(180deg)'
          },
          svgYPadding: 0,
          isRelative: true,
        }} >
        <Container
          maxWidth={false}
          className="container contInsp"
          style={{
            position: "relative", backgroundColor: sepFillColor,
            color: sepFillColorContrastText
          }}
          key="contTabsInspire"
        >
          <Container>
            <AnimateScrollIntoViewport
              durationIn={1}
              durationOut={0.7}
              animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
              exitFramer={{ clipPath: POLYGON_STAR, }}
              styleToMerge={{ clipPath: POLYGON_STAR, }}
              name="TabsPraceExcel" //{`TabsPraceExcel-${deviceSize}`}
              key="TabsPraceExcel" //{`TabsPraceExcel-${deviceSize}`}
            >
              <Suspense>
                <LazyTabsPraceExcel />
              </Suspense>
            </AnimateScrollIntoViewport>
          </Container>
        </Container>
      </SeparatorAbsSvg2>
    </Box>
  );
}

export default MainUp




